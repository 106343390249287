import moment from 'moment';
import commaNumber from 'comma-number';
import { faCheck, faPlus, faPaperPlane, faChartLine } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';

import { getBrand } from './user_helpers';
import { getPrettyDate, getPrettyNumber } from './formatting';
import { getOpportunityRequestsForOpportunity } from './brand_helpers';

export const opportunityConstants = {
  MAX_DAYS_BEFORE_TRACKING_LINK_CAN_BE_CREATED: 7,
  MINUMUM_LINK_CLICKS_TO_REGISTER_AS_EXPECTATION: 3,
  MINUMUM_LINK_CLICKS_TO_REGISTER_AS_EXPECTATION_IN_SINGLE_DAY: 3,
  MINIMUM_MIN_DURATION_BETWEEN_STORIES_FOR_MULTIPLE_MENTIONS: 60
};

export const opportunityTypeData = [
  {
    display: 'Bonus',
    value: 'bonus',
    isRecommended: true,
    description: 'Use this if you want to reward creators for their work while allowing them full creative control about how to promote your brand.'
  },
  {
    display: 'Promotion',
    value: 'promotion',
    description: 'These are opportunities with more traditional promotional asks. Use this if you have more extensive guidelines.'
  },
  {
    display: 'Event',
    value: 'experience',
    description:
      'Use this if you want to invite creators to events and further incentivize them to share their experience at the event with their audiences.'
  }
];

export const opportunityMentionPlatformOptions = [
  {
    display: 'Instagram',
    value: 'instagram'
  },
  {
    display: 'YouTube',
    value: 'youtube'
  },
  {
    display: 'TikTok',
    value: 'tiktok'
  }
];

export const getTypeDisplayForOpportunity = opportunity => opportunityTypeData.find(type => type.value === opportunity.type).display;

export const opportunityMetricFocusOptions = [
  {
    display: 'Clicks',
    value: 'CLICKS'
  },
  {
    display: 'Views',
    value: 'VIEWS'
  },
  {
    display: 'Volume',
    value: 'VOLUME'
  }
];

export const getCoverImageForOpportunity = (opportunity, brandFallback) => {
  return opportunity.coverImage || brandFallback?.mobileBadgeImage || 'https://lightwidget.com/wp-content/uploads/localhost-file-not-found.jpg';
};

// Opportunity Statuses
export const hasOpportunityStarted = opportunity =>
  opportunity &&
  moment(opportunity.trackingStartsAt)
    .startOf('day')
    .diff(moment(), 'minutes') < 0;
export const hasOpportunityEnded = opportunity =>
  opportunity &&
  moment(opportunity.trackingEndsAt)
    .endOf('day')
    .diff(moment(), 'minutes') < 0;
export const isOpportunityActive = opportunity => {
  return hasOpportunityStarted(opportunity) && !hasOpportunityEnded(opportunity);
};
export const isOpportunityExperiential = opportunity => opportunity.type === 'experience';

// Opportunity Request Statuses
export const isOpportunityRequestExpired = request => {
  // If the user has responded, the request is not expired.
  if (request.userAccepted || request.userRejected) return false;

  // If the opportunity has ended, the request is not expired. This is only for users as they cannot take action on expired requests.
  if (request.opportunity && getTimingOnOpportunity(request.opportunity).hasEnded) return true;

  // If no expiration date is set, the request is not expired.
  if (!request.expiresOn) return false;

  return (
    moment(request.expiresOn)
      .startOf('day')
      .diff(moment().startOf('day'), 'minutes') < 0
  );
};

export const getOpportunityRequestAdjustedExpectations = (opportunity, request) => {
  /*
    The standard Opportunity has a set of expectations that can be overridden by the
    Request's expectationAdjustments field. This function calculates the adjusted
    expectations based on the request.

    If the request is empty, it simply returns the opportunity's expectations.

    Keep this in sync with the mobile project.
  */
  const adjustments = JSON.parse(request?.expectationAdjustments || '{}');

  const adjustedLinksExpected = opportunity.linksExpected + (adjustments.links || 0);
  const adjustedLinkingDaysExpected = opportunity.linkingDaysExpected + (adjustments.linkingDays || 0);
  const adjustedMentionsExpected = opportunity.mentionsExpected + (adjustments.mentions || 0);
  const adjustedMentionDaysExpected = opportunity.mentionDaysExpected + (adjustments.mentionDays || 0);

  let adjustmentLabels = [];
  if (Math.abs(+adjustments.links || 0) > 0) adjustmentLabels.push(`${adjustedLinksExpected} Links`);
  if (Math.abs(+adjustments.linkingDays || 0) > 0) adjustmentLabels.push(`${adjustedLinkingDaysExpected} Linking Days`);
  if (Math.abs(+adjustments.mentions || 0) > 0) adjustmentLabels.push(`${adjustedMentionsExpected} Mentions`);
  if (Math.abs(+adjustments.mentionDays || 0) > 0) adjustmentLabels.push(`${adjustedMentionDaysExpected} Mention Days`);

  return {
    // Adjusted Expectations
    linksExpected: adjustedLinksExpected,
    linkingDaysExpected: adjustedLinkingDaysExpected,
    mentionsExpected: adjustedMentionsExpected,
    mentionDaysExpected: adjustedMentionDaysExpected,

    // Information for easier UI handling
    hasAdjustedExpectations: _.values(adjustments).some(value => Math.abs(value) > 0),
    adjustmentLabels
  };
};

export const isOpportunityRequestOutstanding = request => {
  /* Outstanding Requests can still be accepted or rejected by the user. */
  if (request.userAccepted || request.userRejected) return false; // If both parties have accepted, the request is not outstanding.
  if (isOpportunityRequestExpired(request)) return false; // If the request has expired, it is not outstanding.
  return true;
};

export const isOpportunityRequestPastDueDate = (request, opportunity) => {
  /* Past Due Date Requests are accepted but not complete and past the tracking end date */
  const opportunityFromRequestOrDirect = request.opportunity || opportunity;
  return isOpportunityRequestAccepted(request) && hasOpportunityEnded(opportunityFromRequestOrDirect) && !isOpportunityRequestCompleted(request);
};
export const isOpportunityRequestAccepted = request => request?.userAccepted || false;
export const isOpportunityRequestDismissed = request => request?.userRejected || false;
export const isOpportunityRequestInReview = request => isOpportunityResultInReview(request.result);
export const isOpportunityRequestCompleted = request => isOpportunityResultCompleted(request.result);
export const isOpportunityRequestCompletedAndTrackingStillActive = request =>
  isOpportunityResultCompleted(request.result) && isOpportunityActive(request.opportunity);
export const isOpportunityRequestCompletedAndTrackingEnded = request =>
  isOpportunityResultCompleted(request.result) && hasOpportunityEnded(request.opportunity);
export const isOpportunityRequestPaid = request => isOpportunityResultPaid(request.result);
export const isOpportunityRequestActive = request => {
  return isOpportunityRequestAccepted(request) && !isOpportunityRequestCompleted(request) && !hasOpportunityEnded(request.opportunity);
};
export const isOpportunityRequestEventuallyNeedingPayment = request => {
  return isOpportunityRequestActive(request) && !isOpportunityRequestPaid(request) && !isOpportunityRequestPastDueDate(request);
};
export const isOpportunityRequestActiveAndLive = request =>
  isOpportunityRequestActive(request) && request.opportunity && isOpportunityActive(request.opportunity);
export const isOpportunityRequestActiveAndUpcoming = request =>
  isOpportunityRequestActive(request) && request.opportunity && getTimingOnOpportunity(request.opportunity).isNotYetStarted;

// Opportunity Result Statuses
export const isOpportunityResultInReview = result => result?.isInReview || false;
export const isOpportunityResultCompleted = result => result?.isCompleted || false;
export const isOpportunityResultPaid = result => !!result?.Payout_id || !!result?.ManagerPayout_id || false;

export const getMinimumDateForLinkCreation = (result, opportunity) => {
  /*
    You can start creating tracking links:

      - If you accept more than 7 days before the the tracking window starts, you can start creating links 7 days before the tracking window starts.
      - If you accept less than 7 days before the tracking window starts, you can start creating links right after you accept.
      - If you accept after the tracking window starts, links count from the day the tracking window starts.
  */
  const daysBeforeTrackingToAcceptLinks = moment(opportunity.trackingStartsAt).subtract(
    opportunityConstants.MAX_DAYS_BEFORE_TRACKING_LINK_CAN_BE_CREATED,
    'days'
  );
  const minimumDateForLink = result
    ? moment.min(moment.max(moment(result.createdAt), daysBeforeTrackingToAcceptLinks), moment(opportunity.trackingStartsAt))
    : moment.max(daysBeforeTrackingToAcceptLinks, moment());
  return minimumDateForLink;
};

/*
  Opportunity Data Aggregators
*/
export const getSpendDataOnOpportunity = (analytics, opportunity) => {
  const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
  const paymentTiersById = _.keyBy(opportunity.payment_tiers, 'id');
  let [pending, paid] = [0, 0, 0];
  requests.forEach(request => {
    const tier = paymentTiersById[request.OpportunityPaymentTier_id] || {};
    // if (isOpportunityRequestPastDueDate(request, opportunity)) pending += 0;
    if (isOpportunityRequestPaid(request)) paid += tier.fixedFee || 0;
    else if (isOpportunityRequestAccepted(request)) pending += tier.fixedFee || 0;
  });
  return {
    total: pending + paid,
    pending,
    paid
  };
};

export const getStatsOnOpportunity = (analytics, opportunity) => {
  const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
  const total = requests.length;
  const num_outstanding = requests.filter(isOpportunityRequestOutstanding).length;
  const num_accepted = requests.filter(isOpportunityRequestAccepted).length;
  const num_dismissed = requests.filter(isOpportunityRequestDismissed).length;
  const num_completed = requests.filter(isOpportunityRequestCompleted).length;
  return { total, num_outstanding, num_accepted, num_dismissed, num_completed, ...JSON.parse(opportunity.stats || '{}') };
};

export const getTimingOnOpportunity = opportunity => {
  const { trackingStartsAt, trackingEndsAt, eventStartsAt, eventEndsAt } = opportunity;
  const startsInDays = moment(trackingStartsAt)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
  const endsInDays = moment(trackingEndsAt)
    .endOf('day')
    .diff(moment().endOf('day'), 'days');
  const isActive = startsInDays <= 0 && endsInDays >= 0;
  const isNotYetStarted = startsInDays > 0;
  const hasStarted = startsInDays < 0;
  const startsThisWeek = startsInDays <= 7 && startsInDays > 0;
  const startsThisMonth = startsInDays <= 30 && startsInDays > 0;
  const endsThisWeek = endsInDays <= 7 && endsInDays > 0;
  const endsThisMonth = endsInDays <= 30 && endsInDays > 0;
  const hasEnded = endsInDays < 0;
  const daysActive = endsInDays - startsInDays;
  const percentComplete = Math.min(100, Math.max(0, ((startsInDays * -1) / daysActive) * 100));

  // Get tracking labels
  let timelineLabel;
  if (startsInDays > 0)
    timelineLabel = startsInDays <= 7 ? `Starting in ${startsInDays} days` : `Starting ${moment(trackingStartsAt).format('MMMM Do')}`;
  else if (endsInDays === 0) timelineLabel = 'Ends Today';
  else if (endsInDays < 0) timelineLabel = `Completed on ${moment(trackingEndsAt).format('MMMM Do')}`;
  else if (isActive) timelineLabel = `Ends in ${endsInDays} day${endsInDays === 1 ? '' : 's'}`;
  else timelineLabel = 'Start Date Not Set';

  // Get event label
  const eventStartsInDays = moment(eventStartsAt)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');
  const eventEndsInDays = moment(eventEndsAt)
    .startOf('day')
    .diff(moment().endOf('day'), 'days');

  let eventTimelineLabel;
  if (eventStartsInDays > 7) eventTimelineLabel = `Event on ${moment(eventStartsAt).format('MMMM Do')}.`;
  else if (eventStartsInDays > 0) eventTimelineLabel = `Event in ${eventStartsInDays} day${eventStartsInDays === 1 ? '' : 's'}.`;
  else if (eventStartsInDays === 0) eventTimelineLabel = 'Event Today.';
  else eventTimelineLabel = `Event was on ${moment(eventStartsAt).format('MMMM Do')}.`;

  const isEventInFuture = eventStartsInDays > 0;
  const isEventInPast = eventEndsInDays < 0;

  return {
    startsInDays,
    endsInDays,
    eventStartsInDays,
    isActive,
    hasEnded,
    hasStarted,
    startsThisWeek,
    startsThisMonth,
    endsThisWeek,
    endsThisMonth,
    isNotYetStarted,
    percentComplete,
    timelineLabel,
    isEventInFuture,
    isEventInPast,
    eventTimelineLabel,
    durationLabel: getPrettyDate(trackingStartsAt) + ' - ' + getPrettyDate(trackingEndsAt),
    additionalClasses: { 'not-started': isNotYetStarted, active: isActive }
  };
};

export const getDisplayStatsOnOpportunityResult = (result, opportunity, options = {}) => {
  /*
    Returns the statistics required to power the results card for an opportunity.

    This is used for the Opportunity Leaderboard and the cards talent can see
    in their Partners Portal.
  */
  const { linksExpected, linkingDaysExpected, mentionsExpected, mentionDaysExpected, successMetrics } = opportunity;
  const stats = result?.stats ? JSON.parse(result.stats) : {};
  const { clicks, views, orders, volume, mentions, links, emv } = stats;
  const { hideEMV } = options;
  let resultStats = [];

  // Focal points
  const expectsClicks = successMetrics?.includes('CLICKS');
  const expectsViews = successMetrics?.includes('VIEWS');
  const expectsVolume = successMetrics?.includes('VOLUME');

  // Required Additions
  const expectsLinks = linksExpected || linkingDaysExpected;
  const expectsMentions = mentionsExpected || mentionDaysExpected;

  const linkResult = { label: `Link${links === 1 ? '' : 's'}`, display: getPrettyNumber(links || 0), value: links || 0 };
  const clicksResult = { label: `Click${clicks === 1 ? '' : 's'}`, display: getPrettyNumber(clicks || 0), value: clicks || 0, isFocalMetric: expectsClicks }; // prettier-ignore
  const mentionsResult = { label: `Mention${mentions === 1 ? '' : 's'}`, display: getPrettyNumber(mentions || 0), value: mentions || 0 };
  const viewsResult = { label: `View${views === 1 ? '' : 's'}`, display: getPrettyNumber(views), value: views, isFocalMetric: expectsViews };
  const socialViewsResult = { label: `Social View${views === 1 ? '' : 's'}`, display: getPrettyNumber(views), value: views, isFocalMetric: expectsViews }; // prettier-ignore
  const ordersResult = { label: `Order${orders === 1 ? '' : 's'}`, display: getPrettyNumber(orders || 0), value: orders || 0 };
  const volumeResult = { label: 'Volume', display: '$' + getPrettyNumber(volume, { precision: 0 }), value: volume, isFocalMetric: expectsVolume };
  const emvResult = { label: 'EMV', display: '$' + getPrettyNumber(emv, { precision: 0 }), value: emv };

  if (expectsLinks && expectsMentions) {
    // If we have both links and mentions, show essential stats
    resultStats.push(linkResult);
    resultStats.push(clicksResult);
    resultStats.push(mentionsResult);
    resultStats.push(viewsResult);
    resultStats.push(volumeResult);
  } else if (expectsMentions) {
    // If we have mentions, show just the mention stats
    resultStats.push(mentionsResult);
    resultStats.push(viewsResult);
    resultStats.push(volumeResult);
    !hideEMV && resultStats.push(emvResult);
  } else if (expectsLinks) {
    // If we have mentions, show just the link stats
    resultStats.push(linkResult);
    resultStats.push(clicksResult);
    resultStats.push(ordersResult);
    resultStats.push(volumeResult);
  } else {
    // If we have neither, show the essential stats
    resultStats.push(clicksResult);
    resultStats.push(socialViewsResult);
    resultStats.push(ordersResult);
    resultStats.push(volumeResult);
  }

  return resultStats;
};

export const opportunityExpectsLinks = opportunity => opportunity.linksExpected || opportunity.linkingDaysExpected;
export const opportunityExpectsMentions = opportunity => opportunity.mentionsExpected || opportunity.mentionDaysExpected;
export const opportunityHasExpectations = opportunity => opportunityExpectsLinks(opportunity) || opportunityExpectsMentions(opportunity);

export const getDisplayStatsOnOpportunity = opportunity => {
  /*
    Used to power the Opportunity Cards in the Brand Requests Portal.
  */
  const { successMetrics } = opportunity;
  const stats = JSON.parse(opportunity.stats || '{}');
  const { clicks, views, orders, volume, mentions, emv } = stats;

  const expectsLinks = opportunityExpectsLinks(opportunity);
  const expectsMentions = opportunityExpectsMentions(opportunity);

  const focusedOnClicks = successMetrics?.includes('CLICKS');
  const focusedOnViews = successMetrics?.includes('VIEWS');
  const focusedOnVolume = successMetrics?.includes('VOLUME');

  let resultStats = [];
  const viewsResult = { label: `View${views === 1 ? '' : 's'}`, display: getPrettyNumber(views), value: views, className: 'views', isFocalMetric: focusedOnViews}; // prettier-ignore
  const clicksResult = { label: `Click${clicks === 1 ? '' : 's'}`, display: getPrettyNumber(clicks), value: clicks, className: 'clicks', isFocalMetric: focusedOnClicks }; // prettier-ignore
  const ordersResult = { label: `Order${orders === 1 ? '' : 's'}`, display: getPrettyNumber(orders), value: orders, className: 'orders' }; // prettier-ignore
  const mentionsResult = { label: `Mention${mentions === 1 ? '' : 's'}`, display: getPrettyNumber(mentions), value: mentions, className: 'mentions' }; // prettier-ignore
  const volumeResult = { label: 'Volume', display: '$' + getPrettyNumber(volume, { precision: 0 }), value: volume, className: 'volume', isFocalMetric: focusedOnVolume }; // prettier-ignore
  const emvResult = { label: 'EMV', display: '$' + getPrettyNumber(emv, { precision: 0 }), value: emv, className: 'emv' };

  if (expectsLinks && expectsMentions) {
    resultStats.push(clicksResult);
    resultStats.push(volumeResult);
    resultStats.push(viewsResult);
    resultStats.push(emvResult);
  } else if (expectsLinks) {
    resultStats.push(clicksResult);
    !emv && resultStats.push(ordersResult);
    resultStats.push(volumeResult);
    emv && resultStats.push(viewsResult);
    emv && resultStats.push(emvResult);
  } else if (expectsMentions) {
    resultStats.push(mentionsResult);
    resultStats.push(viewsResult);
    resultStats.push(emvResult);
    volume ? resultStats.push(clicksResult) : clicks && resultStats.push(clicksResult);
  } else {
    resultStats.push(mentionsResult);
    resultStats.push(clicksResult);
    resultStats.push(viewsResult);
    volume && resultStats.push(volumeResult);
    emv && resultStats.push(emvResult);
  }

  return _.uniqBy(resultStats, 'label');
};

export const getSortOptionsForOpportunity = (opportunity, requests) => {
  // Select default based on opportunity type or specified default
  const { successMetrics } = opportunity;
  let defaultSortVal = 'per_volume';

  const expectsClicks = successMetrics?.includes('CLICKS');
  const expectsViews = successMetrics?.includes('VIEWS');
  const expectsVolume = successMetrics?.includes('VOLUME');
  const askingForLinks = opportunityExpectsLinks(opportunity);
  const askingForMentions = opportunityExpectsMentions(opportunity);
  if (expectsVolume) defaultSortVal = 'per_volume';
  else if (expectsClicks) defaultSortVal = 'per_click';
  else if (expectsViews) defaultSortVal = 'per_view';
  else if (askingForLinks && askingForMentions) defaultSortVal = 'per_total';
  else if (askingForLinks) defaultSortVal = 'per_volume';
  else if (askingForMentions) defaultSortVal = 'per_emv';

  const getPerUnitValue = (request, unit) => {
    const stats = request.result?.stats ? JSON.parse(request.result.stats) : {};
    stats.total = stats.volume || 0 + stats.emv || 0;
    const { fixedFee } = opportunity.payment_tiers.find(tier => tier.id === request.OpportunityPaymentTier_id) || {};
    return stats[unit] ? fixedFee / stats[unit] : null;
  };

  const getRoiValue = (request, unit) => {
    const stats = request.result?.stats ? JSON.parse(request.result.stats) : {};
    stats.total = stats.volume || 0 + stats.emv || 0;
    const { fixedFee } = opportunity.payment_tiers.find(tier => tier.id === request.OpportunityPaymentTier_id) || {};
    return fixedFee ? stats[unit] / fixedFee : 0;
  };

  const prettyMetric = value => {
    return value >= 100 ? commaNumber(value.toFixed(0)) : value.toFixed(2);
  };
  const parseStats = request => {
    const stats = request.result?.stats ? JSON.parse(request.result.stats) : {};

    return {
      clicks: stats.clicks || 0,
      clicksDisplay: getPrettyNumber(stats.clicks || 0),
      links: stats.links || 0,
      linksDisplay: getPrettyNumber(stats.links || 0),
      mentions: stats.mentions || 0,
      mentionsDisplay: getPrettyNumber(stats.mentions || 0),
      emv: stats.emv || 0,
      emvDisplay: `$${getPrettyNumber(stats.emv || 0, { precision: 0 })}`,
      orders: stats.orders || 0,
      ordersDisplay: getPrettyNumber(stats.orders || 0),
      views: stats.views || 0,
      viewsDisplay: getPrettyNumber(stats.views || 0),
      volume: stats.volume || 0,
      volumeDisplay: `$${getPrettyNumber(stats.volume || 0, { precision: 0 })}`,
      aov: stats.orders ? stats.volume / stats.orders : 0,
      aovDisplay: `$${getPrettyNumber(stats.orders ? stats.volume / stats.orders : 0, { precision: 0 })}`
    };
  };

  return [
    {
      label: 'Advanced Metrics',
      options: [
        {
          label: 'Volume ROI',
          sublabel: 'How much order volume was driven per dollar spent.',
          value: 'per_volume',
          sortFn: {
            display: 'Volume ROI',
            getValue: request => getRoiValue(request, 'volume') || 0,
            sortDirection: 'desc',
            getValueDisplay: request => {
              const volume_roi = getRoiValue(request, 'volume');
              return volume_roi ? `${volume_roi.toFixed(1)}x` : 'N/A';
            }
          }
        },
        {
          label: 'Media ROI',
          sublabel: 'How much media value was driven per dollar spent.',
          value: 'per_emv',
          sortFn: {
            display: 'Media ROI',
            getValue: request => getRoiValue(request, 'emv') || 0,
            sortDirection: 'desc',
            getValueDisplay: request => {
              const emv_roi = getRoiValue(request, 'emv');
              return emv_roi ? `${emv_roi.toFixed(1)}x` : 'N/A';
            }
          }
        },
        {
          label: 'Total ROI',
          sublabel: 'How much volume and media value was driven per dollar spent.',
          value: 'per_total',
          sortFn: {
            display: 'Total ROI',
            getValue: request => getRoiValue(request, 'total') || 0,
            sortDirection: 'desc',
            getValueDisplay: request => {
              const total_roi = getRoiValue(request, 'total');
              return total_roi ? `${total_roi.toFixed(1)}x` : 'N/A';
            }
          }
        },
        {
          label: 'Per Click',
          sublabel: 'How much was spent per click driven.',
          value: 'per_click',
          sortFn: {
            display: 'Per Click',
            getValue: request => getPerUnitValue(request, 'clicks') || 0,
            sortDirection: 'asc',
            getValueDisplay: request => {
              const per_click = getPerUnitValue(request, 'clicks');
              return per_click ? `$${prettyMetric(per_click)}` : 'N/A';
            }
          }
        },
        {
          label: 'Per Order',
          sublabel: 'How much was spent per order driven.',
          value: 'per_order',
          sortFn: {
            display: 'Per Order',
            getValue: request => getPerUnitValue(request, 'orders') || 0,
            sortDirection: 'asc',
            getValueDisplay: request => {
              const per_order = getPerUnitValue(request, 'orders');
              return per_order ? `$${prettyMetric(per_order)}` : 'N/A';
            }
          }
        },
        {
          label: 'Per Social View',
          sublabel: 'How much was spent per social view driven.',
          value: 'per_view',
          sortFn: {
            display: 'Per Social View',
            getValue: request => getPerUnitValue(request, 'views') || 0,
            sortDirection: 'asc',
            getValueDisplay: request => {
              const per_view = getPerUnitValue(request, 'views');
              return per_view ? `$${prettyMetric(per_view)}` : 'N/A';
            }
          }
        }
      ]
    },
    {
      label: 'Traffic & Orders',
      options: [
        {
          label: 'Clicks',
          sublabel: 'Total traffic driven via commissionable links.',
          value: 'clicks',
          sortFn: {
            display: 'Clicks',
            getValue: request => parseStats(request).clicks || 0,
            sortDirection: 'desc',
            getValueDisplay: request => parseStats(request).clicksDisplay
          }
        },
        {
          label: 'Orders',
          sublabel: 'Total orders attributed to that creator.',
          value: 'orders',
          sortFn: {
            display: 'Orders',
            getValue: request => parseStats(request).orders || 0,
            sortDirection: 'desc',
            getValueDisplay: request => parseStats(request).ordersDisplay
          }
        },
        {
          label: 'Average Order Value',
          sublabel: 'Average order size.',
          value: 'aov',
          sortFn: {
            display: 'AOV',
            getValue: request => parseStats(request).aov || 0,
            sortDirection: 'desc',
            getValueDisplay: request => parseStats(request).aovDisplay
          }
        }
      ]
    },
    {
      label: 'Social Metrics',
      options: [
        {
          label: 'Social Mentions',
          sublabel: 'Total social mentions driven.',
          value: 'mentions',
          sortFn: {
            display: 'Mentions',
            getValue: request => parseStats(request).mentions || 0,
            sortDirection: 'desc',
            getValueDisplay: request => parseStats(request).mentionsDisplay
          }
        },
        {
          label: 'Social Views',
          sublabel: 'Total social views driven.',
          value: 'views',
          sortFn: {
            display: 'Views',
            getValue: request => parseStats(request).views || 0,
            sortDirection: 'desc',
            getValueDisplay: request => parseStats(request).viewsDisplay
          }
        },
        {
          label: 'EMV',
          sublabel: 'Estimated media value driven.',
          value: 'emv',
          sortFn: {
            display: 'EMV',
            getValue: request => parseStats(request).emv || 0,
            sortDirection: 'desc',
            getValueDisplay: request => parseStats(request).emvDisplay
          }
        }
      ]
    }
  ].map(section => ({
    ...section,
    options: section.options.map(option => {
      const hasOneRequestWithValue = requests.some(r => option.sortFn.getValue(r));

      return {
        ...option,
        sublabel: null, // Move this to the sublabels array
        sublabels: [...(option.sublabel ? [option.sublabel] : []), ...(hasOneRequestWithValue ? [] : ['No data available for this sort option.'])],
        isDefault: option.value === defaultSortVal,
        isDisabled: !hasOneRequestWithValue,
        sortFn: {
          ...option.sortFn,
          backupGetValues: [
            ...(option.sortFn.backupGetValues || []),
            request => parseStats(request).volume,
            request => parseStats(request).emv,
            request => parseStats(request).clicks
          ],
          backupGetValuesDirection: [...(option.sortFn.backupGetValuesDirection || []), 'desc', 'desc', 'desc']
        }
      };
    })
  }));
};

export const getRecentActivityOnOpportunity = (analytics, opportunity) => {
  /*
    We want to show the most recent activity on an opportunity.

    We start by grabbing all the fields from the Opportunity.recentActivity field
    and then we add in the most recent requests that have been sent or accepted.

    We show the most recent 3 activities.
  */
  let recentActivity = [];
  const NUM_TO_SHOW = 3;

  // First add any recent activity from the opportunity object itself
  const recentActivityFromOpportunity = opportunity.recentActivity?.split(',') || [];
  recentActivityFromOpportunity.forEach(activity => {
    let icon = faPlus;
    if (activity.toLowerCase().includes('accepted')) icon = faCheck;
    if (activity.toLowerCase().includes('sent')) icon = faPaperPlane;
    if (activity.toLowerCase().includes('drove')) icon = faChartLine;

    recentActivity.push({
      activityDate: opportunity.updatedAt,
      display: activity,
      icon
    });
  });

  // If no recent activity, add a default
  if (!recentActivity.length)
    recentActivity.push({
      display: 'You Created This Opportunity',
      icon: faPlus
    });

  return recentActivity.slice(0, NUM_TO_SHOW);
};

/*
  Opportunity Request Data Aggregators
*/
export const getSpendDataOnOpportunityRequest = (request, opportunity) => {
  const paymentTiersById = _.keyBy(opportunity.payment_tiers, 'id');
  const tier = paymentTiersById[request.OpportunityPaymentTier_id] || {};
  const isAccepted = isOpportunityRequestAccepted(request);
  const isPaid = isOpportunityRequestPaid(request);
  const isInReview = isOpportunityRequestInReview(request);
  const isPastDueDate = isOpportunityRequestPastDueDate(request, opportunity);
  const total = tier?.fixedFee || 0;
  return {
    total,
    pending: isAccepted && !isPaid && !isPastDueDate ? total : 0,
    paid: isPaid ? total : 0,
    locked: isAccepted && !isPastDueDate ? total : 0,
    status: isPastDueDate ? 'Past Due Date' : isInReview ? 'In Review' : isPaid ? 'Paid' : isAccepted ? 'Pending' : 'Proposed'
  };
};

/*
  UI specific Helpers
*/
export const getStatusDataForOpportunityRequest = request => {
  let statusDisplay, actionDisplay;

  const isExpired = isOpportunityRequestExpired(request);
  const isDismissed = isOpportunityRequestDismissed(request);
  const expiresInNextWeek = moment(request.expiresOn).diff(moment(), 'days') <= 7;

  if (isDismissed) {
    statusDisplay = 'Dismissed';
    actionDisplay = `Dismissed on ${getPrettyDate(request.userRejectedAt)}`;
  } else if (request.userAccepted) {
    statusDisplay = 'Accepted';
    actionDisplay = `Accepted on ${getPrettyDate(request.userAcceptedAt)}`;
  } else if (isExpired) {
    statusDisplay = 'Expired';
    actionDisplay = `Expired on ${getPrettyDate(request.expiresOn || request.opportunity?.trackingEndsAt)}`;
  } else if (expiresInNextWeek) {
    statusDisplay = 'Expiring Soon';
    actionDisplay = `Expires on ${getPrettyDate(request.expiresOn)}`;
  } else {
    statusDisplay = 'Pending';
    actionDisplay = `Sent on ${getPrettyDate(request.createdAt)}`;
  }

  return { status: request.status, statusDisplay, actionDisplay, isExpired, isDismissed };
};

export const getDisplayForPaymentTier = payment_tier => {
  const { fixedFee, adjCommissionRate } = payment_tier;
  const hasFixedFee = fixedFee && fixedFee > 0;
  const hasAdjCommissionRate = adjCommissionRate > 0 || adjCommissionRate === 0;
  if (hasFixedFee && hasAdjCommissionRate) return `$${commaNumber(fixedFee)} and ${adjCommissionRate}%`;
  if (hasFixedFee) return `$${commaNumber(fixedFee)}`;
  if (hasAdjCommissionRate) return `${adjCommissionRate}%`;
  return `No payment or custom commission rate`;
};

/*
  General Helpers
*/
export const checkValidityOfOpportunity = (opportunity, user) => {
  /*
    Confirm all required fields prior to allowing opportunities to be sent to talent.
  */
  const brand = getBrand(user);

  let canSendOpportunity = true;
  let invalidFields = [];
  let invalidMessages = [];

  if (!opportunity) {
    return {
      canSendOpportunity: false,
      invalidFields: ['opportunity'],
      invalidMessage: 'No opportunity found'
    };
  }

  // Must have a title
  if (!opportunity.title) {
    canSendOpportunity = false;
    invalidFields.push('title');
    invalidMessages.push('Please provide a title for this Opportunity.');
  }

  // Must have a location if it's an experience
  if (!opportunity.location && opportunity.type === 'experience') {
    canSendOpportunity = false;
    invalidFields.push('location');
    invalidMessages.push('Please provide a location for this Experience Opportunity.');
  }

  // Must have a start date
  if (!opportunity.trackingStartsAt) {
    canSendOpportunity = false;
    invalidFields.push('trackingStartsAt');
    invalidMessages.push("Please provide a start date for this Opportunity's tracking window.");
  }

  // Must have a end date
  if (!opportunity.trackingEndsAt) {
    canSendOpportunity = false;
    invalidFields.push('trackingEndsAt');
    invalidMessages.push("Please provide an end date for this Opportunity's tracking window.");
  }

  // Events must have a start time
  if (opportunity.type === 'experience' && !opportunity.eventStartsAt) {
    canSendOpportunity = false;
    invalidFields.push('eventStartsAt');
    invalidMessages.push('Please provide a start time for this Experience Opportunity.');
  }

  // Cannot have more required days than the start to end range
  if (opportunity.trackingStartsAt && opportunity.trackingEndsAt) {
    const daysForTracking = moment(opportunity.trackingEndsAt).diff(moment(opportunity.trackingStartsAt), 'days');

    if (opportunity.linkingDaysExpected && opportunity.linkingDaysExpected > daysForTracking) {
      canSendOpportunity = false;
      invalidFields.push('linkingDaysExpected');
      invalidMessages.push('The number of required linking days for this Opportunity is greater than the tracking window.');
    }

    if (opportunity.mentionDaysExpected && opportunity.mentionDaysExpected > daysForTracking) {
      canSendOpportunity = false;
      invalidFields.push('daysExpected');
      invalidMessages.push('The number of required days for mentions for this Opportunity is greater than the tracking window.');
    }
  }

  // Tracking date cannot be past today
  if (opportunity.trackingEndsAt && moment(opportunity.trackingEndsAt).isBefore(moment(), 'day')) {
    canSendOpportunity = false;
    invalidFields.push('trackingStartsAt');
    invalidMessages.push(
      `The tracking window ended ${moment(opportunity.trackingEndsAt).format('MMMM Do')}, please extend the tracking window to send this Opportunity.`
    );
  }

  if (opportunity.socialTags) {
    const socialTags = opportunity.socialTags.split(',').map(tag => tag.trim().toLowerCase());
    const brandTags = brand.socialTags.split(',').map(tag => tag.trim().toLowerCase());
    const missingFromBrand = socialTags.filter(tag => !brandTags.includes(tag));
    if (missingFromBrand.length) {
      canSendOpportunity = false;
      invalidFields.push('socialTags');
      invalidMessages.push(
        missingFromBrand.length > 1
          ? `The following social tags are not being tracked by social mentions: ${missingFromBrand.join(
              ', '
            )}. Please add these to the social mention tracking list or use one of the valid tags: ${brandTags.slice(0, 5).join(', ')}${
              brandTags.length > 5 ? ', ...' : ''
            }.`
          : `The social tag ${
              missingFromBrand[0]
            } is not being tracked by social mentions. Please add this to the social mention tracking list or use one of the valid tags: ${brandTags
              .slice(0, 5)
              .join(', ')}${brandTags.length > 5 ? ', ...' : ''}.`
      );
    }
  }

  return {
    canSendOpportunity,
    invalidFields,
    invalidMessage: invalidMessages.join(' ')
  };
};
