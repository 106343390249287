import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './PartnerPortalOpportunities.scss';

import { updateOpportunityRequest } from '../../Actions/UserActions';
import { setActiveOpportunity, setActiveOpportunityRequest } from '../../Actions/OpportunityActions';

import { getOpportunityRequests } from '../../Helpers/user_helpers';
import {
  isOpportunityRequestExpired,
  isOpportunityRequestOutstanding,
  isOpportunityRequestActiveAndLive,
  isOpportunityRequestActiveAndUpcoming,
  isOpportunityRequestAccepted,
  isOpportunityRequestDismissed,
  isOpportunityRequestPastDueDate,
  isOpportunityRequestCompleted,
  isOpportunityRequestCompletedAndTrackingStillActive,
  isOpportunityRequestCompletedAndTrackingEnded
} from '../../Helpers/opportunity_helpers';

import OpportunityRequest from '../OpportunityRequests/OpportunityRequest';
import OpportunityRequestResult from '../OpportunityRequests/OpportunityRequestResult';

const PartnerPortalOpportunities = props => {
  const { user } = props;
  const opportunityRequests = getOpportunityRequests(user);
  const newOpportunityRequests = opportunityRequests.filter(isOpportunityRequestOutstanding);
  const liveOppRequests = opportunityRequests.filter(isOpportunityRequestActiveAndLive);
  const upcomingOppRequests = opportunityRequests.filter(isOpportunityRequestActiveAndUpcoming);
  const incompleteOpportunityRequests = opportunityRequests.filter(isOpportunityRequestPastDueDate);
  const rejectedOpportunityRequests = opportunityRequests.filter(isOpportunityRequestDismissed);
  const completedActiveOppRequests = opportunityRequests.filter(isOpportunityRequestCompletedAndTrackingStillActive);
  const completedEndedOppRequests = opportunityRequests.filter(isOpportunityRequestCompletedAndTrackingEnded);
  const expiredOpportunityRequests = opportunityRequests.filter(isOpportunityRequestExpired);

  const sections = [
    {
      display: 'New Opportunities',
      columns: 3,
      requests: _.orderBy(newOpportunityRequests, 'createdAt', 'desc')
    },
    {
      display: 'Live Opportunities',
      columns: 1,
      requests: _.orderBy(liveOppRequests, 'opportunity.trackingEndsAt', 'asc')
    },
    {
      display: 'Upcoming Opportunities',
      columns: 1,
      requests: _.orderBy(upcomingOppRequests, 'opportunity.trackingStartsAt', 'asc')
    },
    {
      display: 'Completed, Active Opportunities',
      columns: 1,
      requests: _.orderBy(completedActiveOppRequests, 'opportunity.trackingEndsAt', 'asc')
    },
    {
      display: 'Completed Opportunities',
      columns: 1,
      requests: _.orderBy(completedEndedOppRequests, 'result.isCompletedAt', 'desc')
    },
    {
      display: 'Opportunities Past Due Date',
      columns: 1,
      requests: _.orderBy(incompleteOpportunityRequests, 'opportunity.trackingEndsAt', 'desc')
    },
    {
      display: 'Dismissed Opportunities',
      columns: 3,
      requests: _.orderBy(rejectedOpportunityRequests, 'userRejectedAt', 'desc')
    },
    {
      display: 'Expired Opportunities',
      columns: 3,
      forceShowRequest: true,
      requests: _.orderBy(expiredOpportunityRequests, 'createdAt', 'desc')
    }
  ].filter(s => s.requests.length);

  return (
    <div className='partner-portal-opportunities-outer'>
      <div className='partner-portal-opportunities-inner'>
        {sections.length ? (
          <div className='opportunity-sections'>
            {sections.map(section => {
              return (
                <div key={section.display} className='opportunity-section'>
                  <div className='opportunity-section-title-container'>
                    <div className='opportunity-section-title'>
                      <div className='title'>{section.display}</div>
                      <div className='count'>{section.requests.length}</div>
                    </div>
                  </div>
                  <div className={cn('opportunity-requests', `grid-${section.columns}`)}>
                    {section.requests.map(request => {
                      return isOpportunityRequestAccepted(request) && !section.forceShowRequest ? (
                        <OpportunityRequestResult
                          isFeaturedCard={!isOpportunityRequestCompleted(request)}
                          key={request.id}
                          request={request}
                          updateOpportunityRequest={props.updateOpportunityRequest}
                          setActiveOpportunityRequest={props.setActiveOpportunityRequest}
                        />
                      ) : (
                        <OpportunityRequest
                          key={request.id}
                          user={user}
                          request={request}
                          updateOpportunityRequest={props.updateOpportunityRequest}
                          setActiveOpportunity={props.setActiveOpportunity}
                          setActiveOpportunityRequest={props.setActiveOpportunityRequest}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className='no-opportunities-container'>
            <div>
              You do not currently have any opportunities. To learn more about opportunities, check out{' '}
              <a href='https://guide.shopmy.us/opportunities' target='_blank' rel='noopener noreferrer'>
                the ShopMy Guide.
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PartnerPortalOpportunities.propTypes = {
  user: PropTypes.object.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  setActiveOpportunity: PropTypes.func.isRequired,
  setActiveOpportunityRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateOpportunityRequest,
  setActiveOpportunity,
  setActiveOpportunityRequest
})(PartnerPortalOpportunities);
